@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

body {
    margin: 0;
    font-family: 'Amiko', sans-serif;
    background-image: linear-gradient(to bottom, rgba(255, 251, 235, 0.5), rgba(255, 255, 255, 1));
    overflow-x: hidden;
}

.header {
    background-color: #141414; 
    height: 75px; 
    width: 100%;
    position: fixed; 
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); 
    padding: 0 20px; 
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; 
    padding: 0 50px;
    box-sizing: border-box;
}

.logo-section {
    display: flex;
    align-items: center;
}

.logo-icon {
    height: 60px;
    width: auto; 
    margin-right: 10px; 
}

.logo-text {
    font-family: 'Amiko', sans-serif;
    font-size: 22px;
    font-weight: 700;
    background: linear-gradient(90deg, #F5F41C 0%, #C46A30 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.nav-links {
    display: flex;
    gap: 30px; 
    margin-left: auto;
    margin-right: 20px;
}

.nav-link {
    font-family: 'Amiko', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
    text-align: center;
}

.nav-link:hover {
    color: #C29112; 
    transition: color 0.3s;
}

.notify-btn-header {
    background-color: #C29112; 
    color: #FFF; 
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.notify-btn-header:hover {
    background-color: #FFF;
    color: #C29112;
    transition: 0.3s;
    text-decoration: none;
}

.hamburger {
    display: none; 
    cursor: pointer;
    font-size: 30px;
    color: white;
}

.hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    cursor: pointer;
    z-index: 1001;
}

.hamburger span {
    height: 3px;
    width: 100%;
    background-color: #fff;
    border-radius: 2px;
}

.nav-links {
    display: flex;
    gap: 30px;
    margin-left: auto;
}

@media (max-width: 768px) {
    .hamburger {
        display: flex; 
    }

    .nav-links {
        display: none; 
        position: absolute;
        top: 75px;
        left: 0;
        width: 100%;
        flex-direction: column;
        background-color: #141414;
        padding: 20px;
        gap: 20px;
    }

    
    .nav-links.active {
        display: flex;
    }
}

@media (max-width: 768px) {
    
    .header-container {
        padding: 0 20px;
    }

    
    .nav-links {
        display: none;
    }

    
    .hamburger {
        display: block;
    }

    
    .logo-icon {
        height: 50px; 
        z-index: 0;
    }

    .notify-btn-header {
        display: none; 
    }
}

@media (max-width: 480px) {
    .header {
        flex-direction: column;
        height: auto;
        padding: 10px 0;
    }

    .logo-text {
        font-size: 18px; 
    }
}

.hero {
    background-color: #FFF9E9;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    padding: 0 20px;
}

.hero-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1728px;
}

.hero-image, .hero-title {
    opacity: 0; 
    transform: translateY(40px); 
    transition: opacity 1.2s ease-out, transform 1.0s ease-in-out; 
}

.hero.visible .hero-image,
.hero.visible .hero-title {
    opacity: 1;
    transform: translateY(0); 
}

.hero-image {
    margin-top: 130px;
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
}

.hero-image img {
    margin-bottom: -20px;
    width: 100%;
    height: auto;
    object-fit: contain;
}

.hero-title {
    font-family: 'Bonheur Royale', serif;
    font-size: 48px;
    color: black;
    padding: 1%;
    margin-top: -10px;
    margin-bottom: -20px;
    font-weight: bold;
    font-style: italic;
}

@media (min-width: 769px) and (max-width: 1200px) {
    .hero {
        padding: 0 20px;
        min-height: 80vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
    }

    .hero-image {
        width: 70%;
        min-width: 400px;
        max-width: 1000px;
        height: auto;
        margin: 20px auto 0;
    }

    .hero-title {
        margin-top: 20px;
        font-size: 36px;
        padding: 0 20px;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .hero {
        padding: 0 15px;
        min-height: 80vh;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .hero-image {
        width: 80%;
        min-height: 30vh;
        min-width: 300px;
        max-width: 500px;
        height: auto;
        margin: 20px auto;
    }

    .hero-title {
        font-size: 29px;
        margin-top: 20px;
        margin-bottom: 15px;
        padding: 0 10px;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .hero {
        padding: 0 10px;
        min-height: 60vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .hero-image {
        width: 90%;
        max-width: 300px;
        margin-top: 15px;
    }

    .hero-title {
        font-size: 24px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0 5px;
        text-align: center;
    }
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(40px); 
    }
    100% {
        opacity: 1;
        transform: translateY(0); 
    }
}

.finale-section {
    position: relative;
    width: 100%;
    max-width: 1200px; 
    margin: 0 auto;
    padding: 50px 20px; 
    text-align: center;
    margin-top: -40px;
}

.finale-title {
    font-family: 'Amiko', sans-serif;
    font-size: 32px; 
    font-weight: 600;
    color: #C29112;
    margin-bottom: 20px;
}

.finale-description {
    font-family: 'Amiko', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6em;
    max-width: 1100px;
    margin: 0 auto 30px;
    padding: 0 20px;
    text-align: center;
}

.finale-images {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 30px 0;
    flex-wrap: wrap; 
}

.finale-images img {
    width: 30%;
    max-width: 400px;
    height: auto;
    object-fit: contain;
}

.finale-offer {
    font-family: 'Amiko', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6em;
    max-width: 1100px;
    margin: 0 auto 30px;
    padding: 0 20px;
    text-align: center;
}

.finale-button {
    background-color: #C29112;
    color: #FFF;
    border: none;
    padding: 15px 30px;
    font-family: 'Amiko', sans-serif;
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
}

.finale-button:hover {
    background-color: #FFF;
    color: #C29112;
    border: 2px solid #C29112;
    transition: 0.3s;
}

@media (max-width: 768px) and (max-width: 1200px) {

    
    .finale-section {
        margin-top : -50px;
        padding: 30px 10px; 
    }

    
    .finale-title {
        font-size: 22px; 
    }

    
    .finale-description, .finale-offer {
        font-size: 16px; 
        line-height: 1.4em; 
        max-width: 90%; 
        padding: 0 10px; 
    }

    
    .finale-images {
        flex-direction: column; 
        margin: 20px 0; 
    }

    .finale-images img {
        width: 90%; 
        max-width: 300px; 
        margin: 10px auto; 
    }

    
    .finale-button {
        font-size: 18px; 
        padding: 10px 20px; 
        margin-top: 20px; 
    }
}

.about-section {
    margin-top: -20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF8E5;
    padding: 40px;
    border-radius: 15px;
    margin: 40px auto;
    width: 80%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}

.about-text {
    max-width: 50%;
}

.about-text h2 {
    font-size: 2.5rem;
    color: #C29112;
    margin-bottom: 20px;
}

.about-text p {
    font-size: 1.2rem;
    color: #333;
    line-height: 1.5;
}

.about-text .highlight {
    color: #C29112;
    font-weight: bold;
}

.notify-btn {
    background-color: #C29112;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 30px;
    transition: background-color 0.3s ease;
}

.notify-btn:hover {
    background-color: #e0a300;
}

.about-image img {
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {

    .about-section {
        margin-top : -25px;
        padding: 30px 15px; 
        width: 90%; 
    }

    .about-container {
        flex-direction: column; 
        text-align: center; 
    }

    .about-text {
        max-width: 100%; 
    }

    .about-text h2 {
        font-size: 2rem; 
        margin-bottom: 15px; 
    }

    .about-text p {
        font-size: 1rem; 
        margin-bottom: 20px; 
    }

    .about-image img {
        max-width: 80%; 
        margin: 20px 0; 
    }

    .notify-btn {
        font-size: 1rem; 
        padding: 8px 15px; 
    }
}

.features-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFF9E9;
    padding: 40px;
    border-radius: 15px;
    margin: 40px auto;
    width: 80%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.features-title {
    font-size: 2.5rem;
    color: #C29112;
    margin-bottom: 50px;
    /* text-align: center; */
    width: 100%;
    margin-top: -5px;
}

.features-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 30px;
}

.feature-card {
    background-color: #2F2F2F;
    border-radius: 15px;
    padding: 30px;
    color: #FFF;
    text-align: center;
    flex: 1 1 30%;
    max-width: 30%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 65%;
    margin-bottom: 20px;
}

.feature-card h3 {
    font-size: 1.5rem;
    color: #C29112;
    margin: 20px 0;
}

.feature-card p {
    font-size: 1rem;
    color: #ddd;
}

.feature-icon {
    max-width: 80px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {

    .features-section {
        padding: 30px 15px; 
        width: 90%; 
    }

    .features-title {
        font-size: 2rem; 
    }

    .features-container {
        flex-direction: column; 
        gap: 20px; 
        align-items: center; 
    }

    .feature-card {
        width: 100%; 
        padding: 20px; 
        height: auto; 
        box-sizing: border-box; 
    }

    .feature-card h3 {
        font-size: 1.3rem; 
    }

    .feature-card p {
        font-size: 0.9rem; 
    }

    .feature-icon {
        max-width: 60px; 
    }
}

@media (max-width: 480px) {
    .features-title {
        font-size: 1.8rem; 
    }

    .feature-card {
        width: 100%; 
        max-width: 100%; 
        padding: 15px; 
    }

    .feature-card h3 {
        font-size: 1.2rem; 
    }

    .feature-card p {
        font-size: 0.85rem; 
    }

    .feature-icon {
        max-width: 50px; 
    }
}

.how-it-works {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF9E9; 
    padding: 40px;
    border-radius: 15px; 
    margin: 40px auto;
    width: 80%; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.how-it-works-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%; 
}

.how-it-works-text {
    max-width: 50%;
    margin-bottom: 20px;
}

.how-it-works-text h2 {
    font-size: 2.5rem;
    color: #C29112;
    margin-bottom: 20px;
}

.how-it-works-text p {
    font-size: 1.2rem;
    color: #333;
    line-height: 1.5;
    margin-bottom: 10px;
}

.highlight {
    color: #B38B00;
    font-weight: bold;
}

.buttons {
    margin-top: 20px;
    display: flex;
    gap: 20px; 
    justify-content: flex-start; 
    flex-wrap: wrap; 
    max-width: 100%; 
}

.app-button {
    background-color: #C29112; 
    color: white;
    padding: 10px 20px; 
    border-radius: 20px; 
    text-decoration: none;
    font-size: 16px; 
    font-weight: bold;
    border: none; 
    cursor: pointer;
    display: inline-block;
    width: auto; 
    max-width: 100%; 
    box-sizing: border-box;
    transition: background-color 0.3s, color 0.3s; 
}

.app-button:hover {
    background-color: #FFF; 
    color: #C29112; 
    border: 2px solid #C29112; 
}

@media (max-width: 768px) {
    .buttons {
        flex-direction: column; 
        gap: 10px; 
        justify-content: center; 
        max-width: 100%; 
    }

    .app-button {
        width: 100%; 
        max-width: 100%; 
        font-size: 14px; 
        padding: 8px 15px; 
    }
}

@media (max-width: 480px) {
    .buttons {
        gap: 8px; 
    }

    .app-button {
        width: 100%; 
        max-width: 100%; 
        font-size: 12px; 
        padding: 8px 20px; 
    }
}

.how-it-works-image {
    max-width: 350px;
    margin-left: -1000px; 
    text-align: right;
}

.how-it-works-image img {
    width: 100%;
    max-width: 800px;
    max-height: 500px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    align-self: center;
}

@media (max-width: 768px) {
    .how-it-works {
        padding: 30px;
        width: 90%; 
    }

    .how-it-works-container {
        flex-direction: column; 
        gap: 20px; 
        text-align: center; 
    }

    .how-it-works-text {
        max-width: 100%;
        margin-bottom: 10px;
    }

    .how-it-works-text h2 {
        font-size: 1.8rem; 
    }

    .how-it-works-text p {
        font-size: 1rem;
    }

    .how-it-works-image {
        max-width: 100%;
        margin-left: 0; 
        text-align: center;
    }

    .how-it-works-image img {
        max-width: 300px;
    }

    .buttons {
        flex-direction: column; 
        gap: 15px; 
        justify-content: center;
    }

    .app-button {
        width: 100%; 
    }
}

@media (max-width: 480px) {
    .how-it-works {
        padding: 20px;
    }

    .how-it-works-text h2 {
        font-size: 1.5rem; 
    }

    .how-it-works-text p {
        font-size: 0.9rem; 
    }

    .how-it-works-image img {
        max-width: 250px;
    }
}

html {
    scroll-behavior: smooth;
}

.services-section {
    display: flex; 
    align-items: center; 
    padding: 50px 100px; 
    background-color: #FFF9E9; 
}

.services-container {
    display: flex; 
    width: 100%; 
}

.services-title {
    font-family: 'Bonheur Royale', serif;
    font-weight: bold; 
    font-size: 60px; 
    line-height: 400px; 
    color: #C29112; 
    margin-right: -20px; 
    flex: 1; 
}

.iphone-image {
    flex: 0 0 auto; 
}

.iphone-image img {
    
    width: 650px; 
    height: relative; 
}

@media (max-width: 1200px) {
    .services-title {
        font-size: 60px; 
        line-height: 80px; 
    }

    .iphone-image img {
        width: 400px; 
    }
}

@media (max-width: 768px) {
    .services-section {
        flex-direction: column; 
        align-items: center; 
    }

    .services-title {
        font-size: 40px; 
        line-height: 50px; 
        margin-right: 0; 
        margin-bottom: 20px; 
        text-align: center; 
    }

    .iphone-image img {
        width: 100%; 
        max-width: 300px; 
    }
}

@media (max-width: 576px) {
    .services-title {
        font-size: 32px; 
        line-height: 40px; 
    }

    .iphone-image img {
        max-width: 250px; 
    }
}

.services-section-one {
    padding: 50px 20px; 
    background-color: #FFF9E9; 
    text-align: center; 
    margin-bottom : 90px;
    margin-top : 90px;
}

.section-title {
    margin-top: -200px;
    font-family: 'Amiko', sans-serif; 
    font-size: 40px; 
    margin-left: -290px;
    color: #C29112; 
    margin-bottom: 30px; 
}

.image-container {
    display: flex; 
    flex-direction: column; 
    align-items: center; 
}

.offer-image {
    width: 80%; 
    max-width: 800px; 
    margin-bottom: 20px; 
    border-radius: 10px; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}

@media (max-width: 768px) {
    .offer-image {
        width: 100%; 
    }
}

.categories-section {
    padding: 50px 20px; 
    text-align: center; 
    background-color: #FFF9E9; 
    margin-top: -180px;
}

.categories-container {
    max-width: 1200px; 
    margin: 0 auto; 
    padding: 20px; 
    background-color: #FFF9E9; 
    border-radius: 15px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.categories-title {
    font-family: 'Amiko', sans-serif; 
    font-size: 32px; 
    color: #C29112; 
    margin-bottom: 40px; 
}

.categories-grid {
    display: grid; 
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px; 
    padding-bottom: 20px; 
}

.category-card {
    background-color: #F5F5F5; 
    border-radius: 10px; 
    overflow: hidden; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    transition: transform 0.3s; 
    text-align: center; 
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.category-card:hover {
    transform: scale(1.05); 
}

.category-image {
    width: 100%; 
    height: 250px; 
    object-fit: cover; 
}

.category-card h3 {
    font-family: 'Amiko', sans-serif; 
    font-size: 18px; 
    color: #C29112; 
    padding: 10px; 
    margin: 0; 
}

@media (max-width: 1024px) {
    .categories-grid {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media (max-width: 768px) {
    .categories-grid {
        grid-template-columns: 1fr; 
    }
}

.form-container {
    max-width: 500px;
    margin: 0 auto; 
    padding: 70px 30px;
    background-color: #FFF9E9; 
    border-radius: 20px; 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); 
    text-align: center; 
}

.form-title {
    font-size: 28px;
    font-weight: bold;
    color: #C29112; 
    margin-bottom: 30px;
    text-align: center; 
}

.notify-form {
    display: flex;
    flex-direction: column; 
    gap: 25px; 
    margin-top: 10px;
}

.form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 15px; 
}

.form-group label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 10px; 
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #C29112; 
    outline: none; 
}

textarea {
    height: 120px;
    resize: vertical; 
}

.submit-btn {
    background-color: #C29112; 
    color: #FFF;
    border: none;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 10px; 
    cursor: pointer;
    width: 100%; 
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease; 
}

.submit-btn:hover {
    background-color: #FFF;
    color: #C29112;
    border: 2px solid #C29112;
    transition: 0.3s;
}

@media (max-width: 768px) {
    .form-container {
        padding: 30px 20px;
    }

    .form-title {
        font-size: 24px;
    }

    .submit-btn {
        font-size: 16px;
    }
}

.coming-soon-container {
    max-width: 600px;
    margin: 100px auto; 
    padding: 50px;
    background-color: #FFF9E9; 
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); 
    text-align: center;
}

.coming-soon-title {
    font-size: 48px;
    font-weight: bold;
    color: #C29112; 
    margin-bottom: 20px;
}

.coming-soon-text {
    font-size: 18px;
    color: #333;
    line-height: 1.6;
    margin-bottom: 30px;
}

.footer {
    background-color: #000;
    color: #FFF;
    padding: 60px 50px;
    font-family: 'Inter', sans-serif;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}

.company-section, .social-media-section, .contact-section, .address-section {
    flex: 1;
    margin-right: 40px;
    min-width: 200px;  
}

.footer-heading {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 20px;
}

.footer-links, .social-links, .contact-info, .address-info {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
}

.footer-links li, .social-links li, .contact-info li, .address-info li {
    margin-bottom: 10px;
    font-size: 16px;
    display: flex; 
    align-items: center;
}

.footer-links a, .social-links a {
    text-decoration: none;
    color: #FFF;
}

.footer-links a:hover, .social-links a:hover {
    text-decoration: underline;
}

.download-heading {
    margin-top: 40px;
}

.notify-btn {
    background-color: #C29112;
    color: #FFF;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
}

.notify-btn:hover {
    background-color: #FFF;
    color: #C29112;
    transition: 0.3s;
    text-decoration: none;
}

.contact-info li span, .address-info li span {
    margin-right: 10px;
    font-size: 18px;
}

.footer-bottom {
    text-align: left;
    margin-top: 40px;
    padding: 20px 50px; 
}

.copyright {
    font-size: 14px;
    color: #FFFFFF; 
    margin: 0;
}

.copyright span {
    font-size: 14px;
    margin-right: 5px;
    color: #FFFFFF; 
}

.copyright strong {
    font-weight: bold; 
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .company-section, .social-media-section, .contact-section, .address-section {
        text-align: center;
        margin-bottom: 40px;
        margin-right: 0;
    }

    .notify-btn {
        margin-top: 20px;
    }
}
